import React from 'react';
import { Col, Row, Visible } from 'react-grid-system';
import styled from 'styled-components';
import Container from '../components/atoms/container';
import Spacer from '../components/atoms/spacer';
import Typography from '../components/atoms/typography';
import HeroBanner from '../components/organisms/heroBanner';
import SEO from '../components/seo';
import heroBannerImg from '../images/panbox_virus_splatter.png';
import panBoxImg from '../images/panbox-right.png';
import { Link, navigate } from 'gatsby';
import Routes from '../services/routes';
import arrowIconRed from '../images/icons/arrow-right-red.png';
import Icon from '../components/atoms/icon';
import girlAndMan from '../images/girl-man.png';
import coronaVirusKissImg from '../images/coronavirus_kiss.png';
import covidShotImg from '../images/covid-shot.png';
import covidCoughImg from '../images/covid-cough.png';
import Button from '../components/atoms/button';

const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.formBackground};
`;

const SubHeader = styled(Typography)`
  font-weight: 300;
`;
const TypographyBold = styled(Typography)`
  font-weight: bold;
`;

const ContainerRightHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Image = styled.img`
  width: 100%;
  margin: auto;
`;
const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
`;
const StyledLinkText = styled(Typography)`
  width: auto;
  font-weight: bold;
`;

const CoronaPage = () => {
  return (
    <>
      <SEO title="Coronaskydd & Coronakit" />
      <PageWrapper>
        <HeroBanner
          leftPane={
            <>
              <Visible sm xs>
                <Spacer spacing={4} />
              </Visible>
              <Container>
                <Row>
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <Typography color="textSecondary" variant="h2">
                      Coronaskydd & Coronakit
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <SubHeader color="textSecondary" variant="h4">
                      Coronavirus har funnits länge (främst hos olika djurarter)
                      och det finns idag sju olika varianter av viruset som kan
                      ge upphov till sjukdom hos människor. Fyra av dessa
                      varianter är vanligt förekommande och orsakar förkylning.
                      covid-19 är ett nytt coronavirus som upptäcktes i slutet
                      av 2019 i Kina.
                    </SubHeader>
                  </Col>
                </Row>
              </Container>
            </>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="coronavirus" src={heroBannerImg} />
            </ContainerRightHero>
          }
        />
        <HeroBanner
          backgroundColor="formBackground"
          leftPane={
            <>
              <Container>
                <Row align="center">
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <Typography variant="h2">
                      Färdiga coronakit från Panbox
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <Typography>
                      Det är svårt att i förväg veta när man behöver skydda sig
                      mot ett nytt virusutbrott. När det sker ett virusutbrott
                      blir det många gånger brist på olika typer av
                      skyddsutrustning då alla vill ha det samtidigt.
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <Typography>
                      För att se till att du och din familj inte blir utan skydd
                      har vi tagit fram färdiga paket som innehåller allt det ni
                      behöver för att skydda er.
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <TypographyBold>
                      Coronakit från panBox skapar trygghet för dig och dina
                      nära och kära. Beställ idag så du är förberedd nästa gång
                      något sker.
                    </TypographyBold>
                  </Col>
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <StyledLink to={Routes.PRODUCTS}>
                      <StyledLinkText color="secondary">
                        Läs mer om våra paket här
                      </StyledLinkText>
                      <Spacer orientation="horizontal" spacing={0.5} />
                      <Icon
                        width={25}
                        src={arrowIconRed}
                        onClick={() => navigate(Routes.PRODUCTS)}
                        alt="pil"
                      />
                    </StyledLink>
                  </Col>
                </Row>
                <Spacer spacing={4} />
              </Container>
            </>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="panbox" src={panBoxImg} />
            </ContainerRightHero>
          }
        />
        <HeroBanner
          backgroundColor="secondary"
          rightPane={
            <>
              <Container>
                <Row align="center">
                  <Col md={12}>
                    <Typography color="textSecondary" variant="h2">
                      Var förberedd med coronaskydd från Panbox
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <Typography color="textSecondary">
                      Med vår hjälp har du alltid tillgång till coronaskydd när
                      du behöver det och behöver inte oroa dig vid nästa
                      virusutbrott. Vi lagerhåller ditt coronakit i våra
                      beredskapslager och skickar ut det när du behöver det.
                      Våra beredskapskit är framtagna för att du ska kunna
                      skydda dig själv och andra vid ett nytt virusutbrott.
                      Genom att använda produkterna (visir, handskar, handsprit
                      m.m.) som medföljer minskar du risken att bli smittad
                      eller smitta andra.
                    </Typography>
                    <Spacer spacing={2} />
                  </Col>
                  <Col md={12}>
                    <Button primary onClick={() => navigate(Routes.ORDER)}>
                      Boka ditt coronakit här.
                    </Button>
                  </Col>
                </Row>
              </Container>
            </>
          }
          leftPane={
            <ContainerRightHero>
              <Spacer spacing={12} />
              <Image alt="panbox" src={girlAndMan} />
            </ContainerRightHero>
          }
        />
        <Spacer spacing={4} />
        <HeroBanner
          backgroundColor="formBackground"
          leftPane={
            <>
              <Container>
                <Row align="center">
                  <Col md={12}>
                    <Typography color="primary" variant="h2">
                      Skydd mot andra typer av virus
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <Typography>
                      Med våra beredskapskit kan du skydda dig själv och din
                      familj mot olika slags virus. Det finns fler återkommande
                      virus utöver covid-19, bland annat säsongsförkylningar,
                      säsongsinfluensa, vinterkräksjuka med mera. För att minska
                      risken att insjukna i ett virus eller smitta andra är det
                      viktigt att du har en plan och ett skydd som fungerar för
                      att minska smittspridningen. Med våra färdiga paket är du
                      alltid förberedd mot många typer av virus.
                    </Typography>
                  </Col>
                </Row>
                <Spacer spacing={4} />
              </Container>
            </>
          }
          rightPane={
            <ContainerRightHero>
              <Image alt="Coronaskydd och coronakit" src={coronaVirusKissImg} />
            </ContainerRightHero>
          }
        />
        <Spacer spacing={4} />
        <HeroBanner
          backgroundColor="primary"
          rightPane={
            <>
              <Container>
                <Row align="center">
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <Typography color="textSecondary" variant="h2">
                      Hur smittar covid-19?
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <Typography color="textSecondary">
                      Covid-19 smittar genom att en person som har viruset
                      sprider små droppar omkring sig genom att prata, hosta,
                      nysa eller vid utandning. Om du andas in dropparna eller
                      får det på händerna och sedan rör vid näsa, ögon eller mun
                      kan du bli smittad av viruset (källa,{' '}
                      <StyledLinkText
                        color="textSecondary"
                        variant="external-link"
                        href="https://www.folkhalsomyndigheten.se/smittskydd-beredskap/utbrott/aktuella-utbrott/covid-19/om-sjukdomen-och-smittspridning/om-viruset-och-sjukdomen/"
                        target="__blank"
                      >
                        Folkhälsomyndigheten
                      </StyledLinkText>
                      ).
                    </Typography>
                  </Col>
                </Row>
                <Spacer spacing={4} />
              </Container>
            </>
          }
          leftPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="coronavirus hosta" src={covidCoughImg} />
            </ContainerRightHero>
          }
        />
        <Spacer spacing={4} />
        <HeroBanner
          backgroundColor="formBackground"
          leftPane={
            <>
              <Container>
                <Row align="center">
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <Typography color="primary" variant="h2">
                      Kan man få covid-19 flera gånger?
                    </Typography>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <Typography>
                      Tyvärr är det så att du kan smittas av covid-19 flera
                      gånger. Risken är dock liten om det är mindre än sex
                      månader sedan du var sjukt senast. För att skydda dig
                      själv och andra är rekommendationen att du vaccinerar dig.
                      Efter att du tagit första sprutan har du efter tre veckor
                      ett gott skydd mot allvarlig sjukdom. Det är dock viktigt
                      att du tar två sprutor för att vaccinet ska ge ett
                      långvarigt och effektivt skydd mot att inte bli svårt sjuk
                      i covid-19 (källa,{' '}
                      <StyledLinkText
                        variant="external-link"
                        target="__blank"
                        href="https://www.krisinformation.se/detta-kan-handa/handelser-och-storningar/20192/myndigheterna-om-det-nya-coronaviruset/vaccin-mot-covid-19/efter-vaccinationen"
                      >
                        Krisinformation
                      </StyledLinkText>
                      ).
                    </Typography>
                    <Spacer spacing={2} />
                    <TypographyBold>
                      Det är viktigt att du håller dig uppdaterad och läser den
                      information som myndigheterna ger gällande covid-19.
                    </TypographyBold>
                  </Col>
                </Row>
                <Spacer spacing={4} />
              </Container>
            </>
          }
          rightPane={
            <ContainerRightHero>
              <Image alt="covid-19 vaccin spruta" src={covidShotImg} />
            </ContainerRightHero>
          }
        />
        <Spacer spacing={4} />
      </PageWrapper>
    </>
  );
};

export default CoronaPage;
